.guest-list{
  position: fixed;
  z-index: 999991;
  background-color: $primary-bg-color;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(100%);
  transition: transform .35s ease;
  padding: 60px;
  overflow-y: auto;

  @media screen and (max-width: 800px) {
    padding: 30px 20px;
  }
  
  &.opened{
    transform: translateX(0);
  }
}

.guest-list__actions{
  padding-top: 5px;
  display: flex;
  flex-direction: row;
}

.guest-list__download{
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border: 1px solid $border-color;
  border-radius: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  @media screen and (max-width: 800px) {
    border-radius: 5px;
    margin-left: 5px;
  }
}

.guest-list__wrapper{
  padding-top: 10px;

  &.printing{
    padding: 30px 50px;

    & .guest-list__cell{
      padding: 6px 12px;
      color: $harmonies !important;
      font-size: 12pt;
    }
  }
}

.guest-list__header{
  display: flex;
  flex-direction: row;

  &-item {
    padding: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ddd;

    @media screen and (max-width: 500px) {
      font-size: 10px;
      line-height: 12px;
    }

    &:first-of-type{
      padding-left: 5px;
    }

    &.short{
      width: 55px;
    }
  }
}

.guest-list__body{
  border-top: 1px solid #363431;
}

.guest-list__row{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #363431;

  &.confirmed > .guest-list__cell{
    opacity: .3;
  }
  // &.limited > .guest-list__cell{
    
  // }
}

.guest-list__section-name{
  padding: 10px 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-transform: uppercase;
  background-color: rgba(170, 170, 170, .05);
  color: $gray;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;

  @media screen and (max-width: 500px) {
    font-size: 11px;
    line-height: 15px;
    padding: 10px;
  }
}

.limited-text{
  font-size: 10px;
  line-height: 20px;
  margin-left: 10px;
  color: $red;
}

.guest-list__cell{
  padding: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $primary-text-color;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: 25px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 17px;
    padding: 10px;
    padding-left: 25px;
  }

  &:first-of-type{
    padding-left: 5px !important;
  }

  &.short{
    min-width: 55px;
  }
  
  &:not(:last-of-type){
    border-right: 1px solid #363431;
  }
}

.guest-list__cell-index{
  font-size: 9px;
  color: $gray;
  position: absolute;
  left: 10px;
}

.guest-list__cell-phone{
  font-size: 10px;
  padding-top: 2px;
  color: $gray;

  & > span{
    color: $white;
    font-weight: bold;
  }
}

.guest-list__trigger{
  width: 60px;
  height: 60px;
  border: 1px dashed $border-color;
  border-radius: 15px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
  background: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(20px);

  @media screen and (max-width: 500px) {
    border: 1px solid $border-color;
  }
}

.vip-text{
  color: #ffed98;
  font-size: 10px;
  margin-left: 5px;
  transform: rotate(90deg);
}

.reservation-assigned-by{
  color: $gray;
  font-size: 10px;
  margin-left: 20px;

  @media screen and (max-width: 500px) {
    margin-left: 10px;
  }
}