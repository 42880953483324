.canvas__form__outer-wrapper{
  position: absolute;
  max-width: 500px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  pointer-events: none;
  opacity: 0;
  z-index: 99999;
  transition: opacity .35s ease, transform .35s ease;

  @media screen and (max-width: 800px) {
    bottom: 40px;
  }
  @media screen and (max-width: 500px) {
    // border-radius: $outer-box-mob-border-radius;
    left: 20px;
    right: 20px;
    bottom: 20px;
    width: auto;
    transform: none;
  }

  &.in-action{
    transform: translateX(-50%) translateY(90px);

    & .canvas__form__close > svg{
      transform: rotate(180deg);
    }

    @media screen and (max-width: 500px) {
      transform: translateY(90px);
    }
  }

  &.opened{
    opacity: 1;
    pointer-events: all;
  }
}

.canvas__form{
  padding: 20px;
  border-radius: $outer-box-border-radius;
  background-color: rgba(28, 27, 25, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  @media screen and (max-height: 550px) {
    padding: 10px;
  }

  &.hidden{
    display: none;
  }
}

.canvas__form__fast-actions{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;

  @media screen and (max-width: 500px) {
    padding-bottom: 10px;
  }
}

.canvas__form__table-actions{
  height: 40px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $inner-box-border-radius;
  background-color: rgba(28, 27, 25, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-sizing: border-box;
  cursor: pointer;
}

.canvas__form__table-action{
  position: absolute;
  left: 0px;
  right: 50%;
  bottom: calc(100% + 10px);
  padding: 10px;
  background-color: $marie;
  border: 1px solid #2F2E2D;
  border-radius: $inner-box-border-radius;
  pointer-events: none;
  opacity: 0;
  transition: opacity .35s ease;


  &.opened{
    opacity: 1;
    pointer-events: all;
  }
}

.canvas__form__table-label{
  position: relative;
  padding: 0 10px;
  font-weight: 600;
  font-size: 16px;
  color: $white;
}

.canvas__form__reservation-data{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.canvas__form__table-options{
  position: absolute;
  left: 0px;
  bottom: calc(100% + 10px);
  padding: 0 10px;
  background-color: $marie;
  border: 1px solid #2F2E2D;
  border-radius: $inner-box-border-radius;
  pointer-events: none;
  opacity: 0;
  transition: opacity .35s ease;


  &.opened{
    opacity: 1;
    pointer-events: all;
  }
}

.canvas__form__table-option{
  padding: 10px 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-of-type){
    border-bottom: 1px solid #2F2E2D;
  }

  & > svg {
    margin-right: 10px;
  }
}

.canvas__form__close{
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: $inner-box-border-radius;
  box-sizing: border-box;
  background-color: rgba(28, 27, 25, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  cursor: pointer;

  & > svg{
    transition: transform .35s ease;
  }
}

.canvas__form__guest-info{

}

.canvas__form__label{
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: $dark-gray;
}

.canvas__form__guest-name{
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: $white;
  padding-top: 5px;
}

.canvas__form__features{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.canvas__form__feature-button{
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px dashed #363431;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.feature-button__icon{
  position: relative;
  display: flex;
}

.feature-button__notification{
  position: absolute;
  right: -7px;
  top: -7px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 400;
  line-height: 9px;
  color: $white;
  background-color: $red;
  border-radius: 10px;
}

.canvas__form__presence{
  flex: 1;
  height: 40px;
  margin: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  color: $marie;
  background-color: $white;
  border-radius: $inner-box-border-radius;
  box-sizing: border-box;
  border: 1px solid $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .35s ease, border-color .35s ease, color .35s ease;

  &.confirmed{
    background-color: rgba(28, 27, 25, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    color: $gray;
  }
}

.canvas__form__new-reservation{
  & > .input-wrapper:first-of-type > .label{
    padding-top: 0;
  }
}

.canvas__form__instructions{
  padding-bottom: 10px;
}

.canvas__form__h3{
  padding-bottom: 5px;
}

.canvas__form__edit-reservation{
  padding-top: 0;
  margin-top: 0;
  border-top: 0px solid rgba(221, 221, 221, 0.3);
  opacity: 0;
  box-sizing: border-box;
  max-height: 0;
  transition: max-height .35s ease, opacity .35s ease, margin .35s ease, padding .35s ease, border .15s ease;
  overflow: hidden;

  &.editing{
    max-height: 208px;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid rgba(221, 221, 221, 0.3);
    opacity: 1;
  }
}

.canvas__form__cancel-reservation{
  color: $red;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
}

// .canvas__step{
//   // padding-bottom: 20px;
// }

.canvas__personal {
  position: relative;
  max-height: 355px;
}

.canvas__step-back {
  position: absolute;
  right: 0;
  top: -5px;
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  z-index: 9;
}

.button.no-margin{
  margin-top: 0;
}

.canvas__form-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: $primary-text-color;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}

.canvas__reservation-table{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.canvas__selected-table{
  color: $primary-text-color;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.canvas__selected-table__badge{
  color: $gray;
  font-size: 12px;
}

.canvas__name-and-phone{
  display: flex;
  flex-direction: row;
  align-items: center;

  & > .input-wrapper{
    flex: 1;
  }
}

.canvas__merge-tables,
.canvas__change-tables{
  padding: 18px;
  border: 1px dashed $harmonies;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  & > svg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.canvas__merge-tables{
  margin-left: 20px;
}

.canvas__change-tables{
  margin-right: 20px;
}

.canvas__call-guest{
  width: 45px;
  height: 45px;
  margin-top: 27px;
  margin-left: 10px;
  border: 1px solid $harmonies;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    border-radius: 5px;
  }

  & > svg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.canvas__reservation-limited{
  margin-top: 20px;
}

.canvas__limited-text{
  text-align: center;
}

.canvas__reservation-detail__label{
  margin-bottom: 2px;
}

.canvas__form__actions{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 10px;
  row-gap: 10px;

  @media screen and (max-width: 500px) {
    column-gap: 8px;

    & > .button{
      text-align: center;
    }
  }

  & > .button:nth-of-type(3):last-of-type,
  & > .button:nth-of-type(1):last-of-type {
    grid-column: span 2;
  }
}

.PhoneInput{
  position: relative;
}

.PhoneInputCountry{
  position: absolute;
  width: 75px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  left: 1px;
}

.PhoneInputInput{
  padding-left: 75px;
}

.PhoneInputCountrySelectArrow{
  color: $white;
}

.PhoneInputCountryIcon{
  box-shadow: 0 0 0 .5px #fff, inset 0 0 0 .5px #fff;
  margin: 0 3px;
}