@import '../setup/style.scss';
@import '../components/sidebar';
@import '../components/buttons';
@import '../components/inputs';
@import '../components/checkbox';

.form-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  @media screen and (max-width: 500px) {
    width: -webkit-fill-available;
  }
}

.form-wrapper__logo{
  text-align: center;
  padding: 60px 0;

  @media screen and (max-width: 500px) {
    padding: 30px 0;
  }

  /* Remove later */
  font-weight: 400;
  font-size: 38px;
  line-height: 45px;
  color: #fff;
}

.form{
  padding: 40px 60px;
  background: $secondary-bg-color;
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: $outer-box-border-radius;
  min-width: 500px;

  @media screen and (max-width: 800px) {
    min-width: 0;
    padding: 40px;
  }
  @media screen and (max-width: 500px) {
    width: -webkit-fill-available;
    padding: 20px;
    border-radius: $outer-box-mob-border-radius;
  }
}

.form__p{
  margin: 20px 0;
}

.login__forgot-password{
  padding: 60px 0;
  text-align: center;

  @media screen and (max-width: 500px) {
    padding: 30px 0;
  }
}