.user{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user__icon{
  width: 50px;
  height: 50px;
  background-color: $icon-box;
  border-radius: $inner-box-border-radius;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user__name{
  margin-bottom: 4px;
}