.content-title{
  // padding-bottom: 60px;

  // @media screen and (max-width: 500px) {
  //   padding-bottom: 30px;
  // }

  &--row{
    //@extend .content-title;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.back-link{
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & > img,
  & > svg{
    margin-right: 10px;
    margin-bottom: 1px;
  }
}

.content-title__button{
  max-width: 200px;
  margin-top: 0;

  @media screen and (max-width: 500px) {
    margin-top: 20px;
  }
}