.waiting-list{
  position: fixed;
  z-index: 999991;
  background-color: $primary-bg-color;
  width: 390px;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(390px);
  transition: transform .35s ease;
  padding: 40px 30px;
  overflow-y: auto;
  border-left: 1px solid $harmonies;
  box-sizing: border-box;

  @media screen and (max-width: 800px) {
    padding: 30px 20px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    transform: translateX(100%);
  }
  
  &.opened{
    transform: translateX(0);
  }

  & .button,
  & textarea{
    border-radius: 10px;
  }
}

.waiting-list__h1{
  padding-bottom: 10px;
}

.request-single{
  padding-top: 20px;
  padding-bottom: 20px;
  
  &:not(:last-of-type) {
    border-bottom: 1px solid $harmonies;
  }
}

.request-single__header{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.request-sigle__guest{
  flex: 1;
  padding-right: 10px;
}

.request-single__persons{
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span{
    margin-left: 5px;
    line-height: 16px;
  }
}

.request-single__time{
  padding-bottom: 2px;
}

.request-single__section{
  padding-top: 5px;

  & > span{
    color: $white;
  }
}

.request-single__actions-wrapper{
  display: flex;
  flex-direction: row;
  // align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
}

.request-single__call-guest{
  width: 45px;
  height: 45px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid $harmonies;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  & > svg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.request-single__actions{
  column-gap: 5px;
  row-gap: 10px;
  display: grid;
}

.request-single__note{
  padding-top: 10px;
}

.request-single__decline{
  padding-top: 10px;
}

.request-single__decline-actions{
  padding-top: 10px;
}

.request-single__characters{
  position: absolute;
  bottom: 20px;
  right: 15px;
  font-size: 12px;
  color: $gray;
  transition: color .35s ease;

  &.warning{
    color: $red;
  }
}

.request-single__age{
  padding-top: 5px;
}