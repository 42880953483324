.prompt-container{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0s ease;
  padding: 20px;

  &.opened {
    opacity: 1;
    pointer-events: all;
    transition: opacity .35s ease;
  }
}

.prompt-box {
  background-color: $bokara;
  border-radius: 20px;
  border: 1px solid $border-color;
  position: relative;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  text-align: center;

  @media screen and (max-width: 500px) {
    padding: 20px;
  }
}

.prompt-box__title {
  margin-bottom: 20px;
}

.prompt-box__message {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #EEEEEE;
  margin-bottom: 20px;
  margin-top: 10px;

  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
  }
}

.prompt-box__actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    margin-top: 10px;
  }
}