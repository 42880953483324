html, body{
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  background-color: $primary-bg-color;
  font-family: $primary-font;
}

#root{
  width: 100%;
  height: 100%;
}

.logo{
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: $primary-text-color;
}

// ----------------------
// Grid
// ----------------------

.container{
  padding-left: 60px;
  padding-right: 60px;
  
  @media screen and (max-width: 1024px){
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 500px){
    padding-left: 20px;
    padding-right: 20px;
  }
}

.grid{
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 20px;
  row-gap: 20px;

  @media screen and (max-width: 500px){
    column-gap: 10px;
  }

  &--2{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 20px;

    @media screen and (max-width: 500px){
      column-gap: 10px;
    }
  }

  &.row-30{
    row-gap: 30px;
  }
  &.row-60{
    row-gap: 60px;
  }
}

// ----------------------
// Typography
// ----------------------

h1{
  font-weight: 400;
  font-size: 38px;
  line-height: 45px;
  color: $primary-text-color;
  margin: 0;

  @media screen and (max-width: 500px) {
    font-size: 28px;
    line-height: 33px;
  }
}

h2{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: $primary-text-color;
  margin: 0;
}

h3{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $primary-text-color;
  margin: 0;

  @media screen and (max-width: 500px) {
    font-size: 15px;
    line-height: 18px;
  }
}

h4{
  @extend h2;
}

h5{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: $primary-text-color;
}

p{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $secondary-text-color;
  margin: 0;
}

.title{
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: $primary-text-color;

  @media screen and (max-width: 500px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.label{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  color: #EEE;

  &--gray{
    @extend .label;
    color: $secondary-text-color;
  }

  &--pb2{
    padding-bottom: 2px;
  }
}

.eyebrow{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: $eyebrow-text-color;
  margin: 0;

  @media screen and (max-width: 500px) {
    font-size: 10px;
    line-height: 12px;
  }
}

a{
  cursor: pointer;
  text-decoration: none !important;
  color: inherit;
}

.underline{
  text-decoration: underline !important;
}

// ----------------------
// universal classes
// ----------------------

picture{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: $primary-bg-color;

  &:not(.loaded):before{
    content: '';
    display: block;
    position: absolute;
    left: -100%;
    top: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, $primary-bg-color 0%, $harmonies 50%, $primary-bg-color 100%);
    animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
  
  & > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
    opacity: 0;

    &.fadeIn{
      transition: opacity .35s ease;
    }
  }

  &.loaded > img{
    opacity: 1;
  }
}

@keyframes load {
  from {
      left: -100%;
  }
  to   {
      left: 100%;
  }
}

.img-180{
  transform: rotate(180deg);
}

.content{
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 160px;
  position: relative;

  @media screen and (max-width: 800px) {
    padding-top: 140px; // 80px + 60px ( header is 80px ) with position fixed
  }
  @media screen and (max-width: 500px) {
    padding-top: 110px;  // 80px + 30px ( header is 80px ) with position fixed
    padding-bottom: 120px;
  }
}

.inner-content-grid{
  grid-column: span 8;

  @media screen and (max-width: 1280px) {
    grid-column: span 10;
  }
  @media screen and (max-width: 800px) {
    grid-column: span 12;
    row-gap: 0;
  }
}

.content-section{
  padding-top: 60px;

  @media screen and (max-width: 500px) {
    padding-top: 30px;
  }
}

.empty-section{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $secondary-text-color;
  margin: 0;
  padding-top: 60px;

  @media screen and (max-width: 500px) {
    padding-top: 30px;
  }
}

.error{
  color: $red;
}
.success {
  color: $green;
}

// ----------------------
// hovers
// ----------------------

.hover--opacity{
  transition: opacity .35s ease;
  opacity: 1;

  @media (hover: hover) {
    &:hover{
      opacity: .85;
    }
  }
}

.hover--underline{
  position: relative;
  cursor: pointer;

  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    height: 1px;
    background-color: $primary-text-color;
    transition: right .35s ease;
  }

  @media (hover: hover) {
    &:hover::after{
      right: 0px;
    }
  }
}

.hover--translate-right{
  transition: transform .35s ease;
  transform: translateX(0);

  @media (hover: hover) {
    &:hover{
      transform: translateX(3px);
    }
  }
}