.canvas__wrapper{
  position: fixed;
  z-index: 99999;
  background-color: $primary-bg-color;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(100%);
  animation: slideIn .35s ease forwards;

  & > canvas{
    width: 100%;
    height: 100%;
  }
}

.canvas__header{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding-top: 60px;
  pointer-events: none;

  @media screen and (max-width: 500px) {
    padding-top: 20px;
  }
}

.canvas__nav{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  @media screen and (max-width: 500px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  & > .back-link{
    margin-bottom: 0;
    pointer-events: all;

    & > .label--gray {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }
}

.floor__nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: all;

  & > p{
    cursor: pointer;
  }

  & > p.active {
    color: $primary-text-color;
  }

  & > p:not(:first-of-type) {
    margin-left: 40px;
  }
}

.canvas__options{
  position: relative;

  @media screen and (max-width: 500px) {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
  }
}

.canvas__options-menu{
  background-color: $bokara;
  border-radius: 10px;
  position: absolute;
  width: max-content;
  min-width: 230px;
  top: calc(100% + 10px);
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .35s ease;
  overflow: hidden;

  &.opened{
    opacity: 1;
    pointer-events: all;
  }
}

.canvas__option{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $white;
  padding: 10px 15px;
  border-bottom: 1px solid $marie;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color .35s ease;

  &.unavailable{
    pointer-events: none;
    
    & > .canvas__option__text{
      opacity: .3;
    }
  }

  @media (hover: hover) {
    &:hover{
      background-color: $harmonies;
    }
  }
}

.canvas__option__text{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.canvas__option__p{
  color: $white;
  margin-left: 13px;
  font-size: 16px;
  line-height: 22px;
}

.canvas__options-menu-trigger{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  border: 1px solid $harmonies;
  border-radius: 10px;
  cursor: pointer;
  pointer-events: all;
  background: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(20px);
  position: relative;

  &.no-border{
    border: none !important;
    margin-top: 0 !important;
  }

  @media screen and (max-width: 500px) {
    margin-top: 10px;
  }
   
  & > div{
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $white;

    &:not(:first-of-type){
      margin-left: 5px;
    }
  }
}

.canvas__options__notifications{
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  top: -7.5px;
  right: -7.5px;
  border-radius: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 22px;
  background-color: $red !important;
  color: $white;
}

.canvas__option__notifications{
  margin-left: 5px;
  color: $red;
}

.pick-a-table__explain{
  margin-top: 10px;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.fast-limit{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fast-limit__cta{
  padding: 10px 20px;
  border: 1px solid $harmonies;
  background: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  color: $white;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  pointer-events: all;
  cursor: pointer;

  & > svg{
    margin-right: 5px;
  }
}