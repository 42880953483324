.invite{
  grid-column: span 3;
  padding: 20px;
  border: 1px solid $border-color;
  border-radius: $outer-box-border-radius;
  background-color: $primary-bg-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color .35s ease;

  @media screen and (max-width: 1440px) {
    grid-column: span 4;
  }
  @media screen and (max-width: 1280px) {
    grid-column: span 6;
  }
  @media screen and (max-width: 500px) {
    grid-column: span 12;
  }
}

.invite__info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.invite__img{
  width: 100%;
  padding-top: 100%;
  background-color: $icon-box;
  border-radius: $inner-box-border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.invite__h2{
  margin-bottom: 4px;
}

.invite__buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  width: 100%;

  & > .button:not(:last-of-type) {
    margin-right: 10px;
  }
}

.invite-wrapper{
  max-width: 400px;
  width: 100%;

  @media screen and (max-width: 500px) {
    max-width: 335px;
  }
}