.reservation-details{
  position: fixed;
  z-index: 999991;
  background-color: $primary-bg-color;
  width: 390px;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(390px);
  transition: transform .35s ease;
  padding: 40px 30px;
  overflow-y: auto;
  border-left: 1px solid $harmonies;
  box-sizing: border-box;

  @media screen and (max-width: 800px) {
    padding: 30px 20px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    transform: translateX(100%);
  }
  
  &.opened{
    transform: translateX(0);
  }
}


.reservation-details__h1{
  padding-bottom: 10px;
}

.reservation-details__person{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.reservation-details__person-info{

}

.reservation-details__person-actions{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.reservation-details__person-action{
  width: 45px;
  height: 45px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid $harmonies;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  & > svg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.reservation-details__assigned-by{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.reservation-details__time{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
}

.reservation-details__attributes{
  padding-top: 20px;
}

.reservation-details__attribute{
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span{
    margin-left: 5px;
    line-height: 16px;
  }
}