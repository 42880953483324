.sidebar{
  width: 300px;
  display: flex;
  z-index: 9999;

  @media screen and (max-width: 800px) {
    position: fixed;
    left: 0;
    right: 0;
    width: auto;
  }

  &.opened{
    & > .sidebar__header{
      background-color: $secondary-bg-color;
    }

    & > .sidebar__menu{
      transform: translateX(0);
    }
    
    & .hamburger{
      

      &::before{
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
      &::after{
        width: 100%;
        bottom: auto;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
}

.sidebar__header{
  display: none;
  width: 100%;
  position: relative;
  height: 80px;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-color: $primary-bg-color;
  transition: background-color .35s ease;
  
  @media screen and (max-width: 800px) {
    display: flex;
  }
}

.sidebar__container{
  padding: 30px;

  @media screen and (max-width: 500px) {
    padding: 20px;
  }
}

.sidebar__menu{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid $border-color;
  background-color: $secondary-bg-color;
  transition: transform .35s ease;

  @media screen and (max-width: 800px) {
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    overflow-y: auto;
    transform: translateX(-100%);
  }
}

nav{
  padding-top: 20px;

  & > ul{
    list-style-type: none;
    padding: 0;
    margin: 0;

    & > li{
      padding: 10px 30px;
      position: relative;
      color: $secondary-text-color;
      cursor: pointer;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      transition: color .35s ease;

      & > a.active,
      & > :hover{
        color: $primary-text-color;
        opacity: 1 !important;

        &::after{
          content: '';
          position: absolute;
          height: 30px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: $primary-text-color;
          width: 5px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        & .nav-icon{
          stroke: #fff;
        }
      }

      @media (hover: hover) {
        &:hover{
          color: $white;
        }
      }
      
      & > a{
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      & svg{
        margin-right: 20px;
      }

      & .icon-wrapper{
        display: flex;
        margin-right: 20px;
        position: relative;
      }

      & .nav-icon{
        stroke: #AAAAAA;
        transition: stroke .35s ease;
      }
    }
  }
}

.nav__label{
  padding: 10px 30px;
}

.hamburger{
  width: 28px;
  height: 13px;
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    left: 20px;
  }

  &::before,
  &::after{
    content: '';
    position: absolute;
    left: 0;
    background-color: $primary-text-color;
    height: 1px;
    transform-origin: center;
    transition: transform .35s ease, top .35s ease, width .35s ease;
  }

  &::before{
    top: 0;
    width: 100%;
  }
  &::after{
    bottom: 0;
    width: 50%;
  }
}

.icon__notifications{
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  left: -6px;
  top: -6px;
  background-color: $light-gray;
  font-size: 12px;
  color: $marie;
  display: flex;
  align-items: center;
  justify-content: center;
}