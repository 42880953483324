.edit__box{
  grid-column: span 6;
  position: relative;

  &--row{
    grid-column: span 12;
  }

  @media screen and (max-width: 500px) {
    grid-column: span 12;
  }
}

.edit__banner-wrapper{
  max-width: 200px;
  width: 100%;

  @media screen and (max-width: 800px) {
    padding-bottom: 20px;
  }
}

.edit__banner{
  padding-top: 100%;
  position: relative;
  border: 1px dashed #333230;
  border-radius: $outer-box-border-radius;
  overflow: hidden;
}

.edit__upload-icon{
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: $inner-box-border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.quantity{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.quantity__value{
  margin: 0 20px;
  color: $white;
}

.quantity__minus,
.quantity__plus{
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  position: relative;
  cursor: pointer;

  &::after{
    content: '';
    position: absolute;
    width: 9px;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #ACACAC;
  }
}

.quantity__plus::before{
  content: '';
  position: absolute;
  width: 1px;
  height: 9px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ACACAC;
}

.edit__actions{
  grid-column: 1 / span 12;
  padding-top: 40px;

  @media screen and (max-width: 500px) {
    padding-top: 30px;
  }
}

.edit__button{
  grid-column: span 4;
  margin-top: 0;

  @media screen and (max-width: 1280px) {
    grid-column: span 5;
  }
  @media screen and (max-width: 800px) {
    grid-column: span 6;
  }
  @media screen and (max-width: 500px) {
    grid-column: span 12;
  }
}

.restrict-tables__cta{
  position: fixed;
  width: 300px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 30px;
  z-index: 99;
  box-sizing: border-box;
  background: rgba(67, 67, 67, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .35s ease;

  @media screen and (min-width: 1600px) {
    width: 400px;
  }
  @media screen and (max-width: 1280px) {
    width: 100px;
    text-align: center;
  }
  @media screen and (max-width: 800px) {
    top: auto;
    width: auto;
    left: 0;
    height: 60px;
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    flex-direction: row;
    text-transform: uppercase;

    & > h2{
      font-size: 16px;
      line-height: 19px;
    }
  }

  @media (hover: hover) {
    &:hover{
      background: rgba(67, 67, 67, 0.5);
    }
  }
}

.restrict-tables__arrow{
  margin-top: 60px;

  @media screen and (max-width: 1280px) {
    height: 30px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 0;
    margin-left: 10px;
    height: 10px;
  }
}

.fake-reservation-mode__h2{
  padding-bottom: 20px;
  padding-top: 30px;
}