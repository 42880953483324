.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  color: $gray;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.in-list{
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 5px;
  background-color: $bokara;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: $bokara;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: $bokara;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 6.5px;
  top: 3.5px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}