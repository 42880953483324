.event-details__box{
  padding-bottom: 40px;
}

.event-details__banner{
  margin: 30px 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: $outer-box-border-radius;

  @media screen and (max-width: 500px) {
    margin: 20px 0;
  }
}

.event-details__when{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
}

.event-details__icon-box{
  display: flex;
  flex-direction: row;
  align-items: center;

  & > img,
  & > svg{
    margin-right: 10px;
  }

  &:not(:first-of-type){
    margin-left: 20px;
  }
}

.event-details__single{
  grid-column: span 6;

  &.big{
    grid-column: span 12;
  }
}

.event-details__single-name{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #EEEEEE;
  margin-bottom: 5px;
}

.reservation-available{
  text-align: center;
  margin-top: 30px;
}

.data-box{
  padding: 25px;
  border: 1px solid $night-rider;
  background-color: $night-rider;
  border-radius: 20px;
  margin-top: 20px;

  &.center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.event-details__confirmations{
  padding: 25px;
  position: relative;
  min-height: 126px;
  transition: min-height .35s ease;
  transition-delay: .35s;

  &.confirmations-sending {
    & > .event-details__countdown-loader{
      opacity: 1;
    }
    & > .event-details__countdown{
      opacity: 0;
    }
  }
  &.confirmations-sent{
    & > .event-details__countdown-loader{
      opacity: 0;
    }
    & > .event-details__countdown{
      opacity: 0;
      display: none;
    }
    & > .event-details__confirmations-data{
      opacity: 1;
      transition: opacity .35s ease;
      transition-delay: .5s;
    }
    min-height: 0;
  }
}

.event-details__countdown{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity .35s ease;
  z-index: 2;
  position: relative;
}

.event-details__countdown-text{
  color: $gray;
}

.event-details__countdown-clock{
  padding-top: 10px;
  font-size: 25px;
  line-height: 32px;
}

.event-details__countdown-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 8px;
  bottom: 8px;
  top: 0;
  opacity: 0;
  transition: opacity .35s ease;
  z-index: 1;
}

.event-details__confirmations-data{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: opacity .35s ease;
  transition-delay: .35s;
}

.event-details__confirmations-status{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-details__confirmations-text,
.event-details__confirmations-number{
  color: $white;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.event-details__confirmations-number{
  font-weight: 600;
}

.event-details__demand-notice{
  font-size: 12px;
  color: $gray;
  // text-align: left;
  // width: -webkit-fill-available;
  margin-top: 20px;
}